.HeaderV2 {
    background-color: var(--color_primary);
    height: 55px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;

    @media (max-width: 720px) {
        padding: 16px 6px;
        gap: 10px;
    }

    &__left-section{
        width: 116px;
        aspect-ratio: 1;
        cursor: pointer;
        scale: 0.8;
    }

    &__middle-section{
        flex: 1;
        max-width: 350px;
    }
}

.HeaderV2AutocompleteSearch {
    $b: &;

    #{ $b }__input {
        height: 30px;
    }
}

.HeaderV2UserMenu {

    display: flex;
    flex-direction: row;

    $b: &;

    #{ $b }__icon {
        background-color: var(--color_gray_5);
        width: 35px;
        height: 35px;
        aspect-ratio: 1;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;

        #{ $b }__icon-initials {
            color: var(--color_white_1);
            font-size: 1.25rem;
            line-height: 1;
        }
    }

    #{ $b }__beta {
        width: 35px;
        height: 35px;
        aspect-ratio: 1;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        margin-right: 2em;

        svg{
            width: 18px;
            height: 18px;
            fill: var(--color_white_1);
        }
    }

    .betaVisible{
        background-color: #2A7AAE;
    }

    .betaInvisible{
        background-color: #AED6F0;
        svg{
            fill: var(--color_gray_1);
        }
    }
}